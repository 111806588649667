const UserTypes = {
    SAVAN_FETCH_User_START: 'SAVAN_FETCH_User_START',
    SAVAN_FETCH_User_SUCCESS: 'SAVAN_FETCH_User_SUCCESS',
    SAVAN_FETCH_User_FAILED: 'SAVAN_FETCH_User_FAILED',
    SAVAN_SAVE_DISTRIBUTOR_START: 'SAVAN_SAVE_DISTRIBUTOR_START',
    SAVAN_SAVE_DISTRIBUTOR_SUCCESS: 'SAVAN_SAVE_DISTRIBUTOR_SUCCESS',
    SAVAN_SAVE_DISTRIBUTOR_FAILED: 'SAVAN_SAVE_DISTRIBUTOR_FAILED',
}

export default UserTypes


