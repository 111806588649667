import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { MatxLayouts } from './index'
import { MatxSuspense } from 'app/components/index'
import useSettings from 'app/hooks/useSettings'
import { appInit } from "../../redux/actions/AppActions";

const MatxLayout = (props) => {
    const { settings } = useSettings()
    const Layout = MatxLayouts[settings.activeLayout]
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(appInit())
    }, [dispatch])

    return (
        <MatxSuspense>
            <Layout {...props} />
        </MatxSuspense>
    )
}

export default MatxLayout
