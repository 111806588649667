import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchBrandsSuccess, fetchBrandsFailed } from '../actions/BrandsActions'
import * as BrandsApi from '../../services/BrandsService'
import BrandsTypes from '../types/BrandsTypes'
import AppTypes from '../types/AppTypes'

function* fetchBrands() {
    try {
        const { data: { data } } = yield call(BrandsApi.fetchBrands)
        yield put(fetchBrandsSuccess(data))
    } catch (error) {
        yield put(fetchBrandsFailed(error))
    }
}

const BrandsSagas = [
    takeLatest(BrandsTypes.SAVAN_FETCH_BRANDS_START, fetchBrands),
    takeLatest(AppTypes.SAVAN_APP_INIT, fetchBrands),
]

export default BrandsSagas
