import { get, last } from 'lodash'
import { sortAppointmentsByDate, transformPhoneNumber } from "./AppointmentsUtils";
import { dateToCompatibleFormat } from "../../utils/utils";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
export function transformClients(clients) {
  if (!Array.isArray(clients)) return []
  return clients.map((client) => ({
    ...client,
    phoneNumber: transformPhoneNumber(client.phoneNumber),
    lastAppointmentDate: getClientLastAppointment(client)
  }))
}

function getClientLastAppointment(client) {
  let appointments = get(client, 'vehicles[0].appointments', null)
  if (!appointments || !Array.isArray(appointments) || appointments.length === 0) return 'aucun rendez-vous enregistré'
  appointments = sortAppointmentsByDate(appointments)
  const lastAppointment = last(appointments)
  const { startDateTime } = lastAppointment
  let date = dateToCompatibleFormat(startDateTime)
  return format(date, 'dd/MM/y', { locale: fr })
}