import AvailabilitiesTypes from "../types/AvailabilitiesTypes";

export const fetchAvailabilitiesStart = (params = null) => ({
  type: AvailabilitiesTypes.SAVAN_FETCH_AVAILABILITIES_START,
  payload: params
})

export const fetchAvailabilitiesSuccess = (availabilities) => ({
  type: AvailabilitiesTypes.SAVAN_FETCH_AVAILABILITIES_SUCCESS,
  payload: availabilities
})

export const fetchAvailabilitiesFailed = (error) => ({
  type: AvailabilitiesTypes.SAVAN_FETCH_AVAILABILITIES_FAILED,
  payload: error
})

export const deleteAvailabilityStart = (id) => ({
  type: AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_START,
  payload: id
})

export const deleteAvailabilitySuccess = () => ({
  type: AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_SUCCESS
})

export const deleteAvailabilityFailed = (error) => ({
  type: AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_FAILED,
  payload: error
})

export const saveAvailabilityStart = (availability) => ({
  type: AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_START,
  payload: availability
})

export const saveAvailabilitySuccess = () => ({
  type: AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_SUCCESS
})

export const saveAvailabilityFailed = (error) => ({
  type: AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_FAILED,
  payload: error
})