import React, {lazy} from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const DashboardPage = Loadable(lazy(() => import('./DashboardPage')))
const UserProfilePage = Loadable(lazy(() => import('./profile/UserProfilePage')))
const AppointmentsPageToConfirm = Loadable(lazy(() => import('./appointments/AppointmentsPageToConfirm')))
const AppointmentsPageComing = Loadable(lazy(() => import('./appointments/AppointmentsPageComing')))
const ClientsPage = Loadable(lazy(() => import('./clients/ClientsPage')))
const HistoryPage = Loadable(lazy(() => import('./HistoryPage')))

export const DashboardRoutes = [
    {
        path: '/agenda',
        element: <DashboardPage/>
    },
    {
        path: '/profile',
        element: <UserProfilePage/>
    },
    {
        path: '/a-confirmer',
        element: <AppointmentsPageToConfirm/>
    },
    {
        path: '/a-venir',
        element: <AppointmentsPageComing/>
    },
    {
        path: '/clients',
        element: <ClientsPage/>
    },
    {
        path: '/historique',
        element: <HistoryPage />,       
    }
];
