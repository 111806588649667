import axios from "../utils/axios";

export const fetchAppointments = (id, params = null) => axios.get(`distributors/${id}/appointments`, {
  params: {...params}
})

export const fetchAppointmentsCount = (id, params = null) => axios.get(`distributors/${id}/appointments/count`, {
  params: {...params}
})


export const updateAppointment = (id, data) => axios.put(`/appointments/${id}`, data)

export const cancelAppointment = (id, note) => axios.post(`appointments/cancel/${id}`, { note })
