import {
    startOfDay,
    endOfDay,
    startOfMonth,
    lastDayOfMonth,
    isSunday,
    previousSunday,
    nextSaturday,
    format,
} from 'date-fns'
import { AVAILABILITY_DAYS, CALENDAR_VIEWS } from 'app/utils/constant'
import { dateToCompatibleFormat } from 'app/utils/utils'
import { get } from "lodash";

export const getDateInterval = (date, view) => {
    const dateFormat = 'yyyy-MM-dd'
    switch (view) {
        case CALENDAR_VIEWS.WEEK: {
            const starDate = isSunday(date) ? date : previousSunday(date)
            const endDate = nextSaturday(starDate)
            return {
                min: `${format(startOfDay(starDate), dateFormat)} 00:00:00`,
                max: `${format(endOfDay(endDate), dateFormat)} 23:59:59`,
            }
        }
        case CALENDAR_VIEWS.MONTH:
            return {
                min: `${format(startOfDay(startOfMonth(date)), dateFormat)} 00:00:00`,
                max: `${format(endOfDay(lastDayOfMonth(date)), dateFormat)} 23:59:59`,
            }
        default:
            return {
                min: `${format(startOfDay(date), dateFormat)} 00:00:00`,
                max: `${format(endOfDay(date), dateFormat)} 23:59:59`,
            }
    }
}

export const transformAvailability = (availability) => {
    if(!availability) return null;
    const {dayDispo, appointments} = availability
    const hourDispoStart = get(availability, 'hourDispoStart', '').substring(0, 5)
    const hourDispoEnd = get(availability, 'hourDispoEnd', '').substring(0, 5)
    const weekDays = get(availability, 'weekDays', [])
    const date = dateToCompatibleFormat(dayDispo)
    const startDate = dateToCompatibleFormat(`${dayDispo}T${hourDispoStart}`)
    const endDate = dateToCompatibleFormat(`${dayDispo}T${hourDispoEnd}`)
    const days = AVAILABILITY_DAYS.map(day => {
        const found = weekDays.find(item => item === day.value)
        return found ? {
            ...day,
            selected: true
        } : {...day}
    })
    return {
        ...availability,
        date,
        startDate,
        endDate,
        title: `1 disponibilité \n${!appointments ? '1': '0'} restantes`,
        availability: true,
        free: !appointments,
        weekDays: days,
        hourDispoStart,
        hourDispoEnd
    }
}

export const findIndexInList = (availability, list) => {
    return list.findIndex(
      item => item.dayDispo === availability.dayDispo && item.dayPeriod === availability.dayPeriod
      && item.hourDispoStart === availability.hourDispoStart && item.hourDispoEnd === availability.hourDispoEnd
    )
}

export const transformAvailabilitiesList = (availabilities) => {
    if(!Array.isArray(availabilities)) return []
    const list = []
    availabilities.forEach(availability => {
        const newAvailability = transformAvailability(availability)
        const index = findIndexInList(newAvailability, list)
        if (index === -1) {
            list.push({ ...newAvailability, slots: [newAvailability] })
        } else {
            const parentAvailability = list[index]
            parentAvailability.title = `${parentAvailability.slots.length + 1 } disponibilités \n${parentAvailability.slots.filter(availability => availability.free === true).length} restantes`
            parentAvailability.slots.push(transformAvailability(newAvailability))
            list.splice(index, 1, parentAvailability)
        }
    })
    return list
}

export const availabilityToForm = availability => {
    const { date, startDate, endDate } = availability
    return {
        ...availability,
        dayDispoStart: date,
        dayDispoEnd: date,
        hourDispoStart: startDate,
        hourDispoEnd: endDate,
    }
}
