import LoginTypes from "../types/LoginTypes";
import storage from 'redux-persist/lib/storage';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    accessToken: null,
    user: null,
    authError: null,
}

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LoginTypes.SAVAN_MEMBER_INIT: {
            const {isAuthenticated, user} = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user
            }
        }
        case LoginTypes.SAVAN_MEMBER_LOGIN_SUCCESS: {
            const {isAuthenticated, accessToken, user} = action.payload;

            return {
                ...state,
                isAuthenticated,
                accessToken,
                user
            }
        }
        case LoginTypes.SAVAN_MEMBER_LOGOUT_SUCCESS: {
            const {isAuthenticated} = action.payload;
            storage.removeItem('persist:root');

            return {
                ...state,
                isAuthenticated
            }
        }
        default: {
            return {...state}
        }
    }
}

export default LoginReducer;
