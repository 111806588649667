import BrandCategoriesTypes from "../types/BrandCategoriesTypes";

export const fetchBrandCategoriesStart = () => ({
    type: BrandCategoriesTypes.SAVAN_FETCH_BRAND_CATEGORIES_START
})

export const fetchBrandCategoriesSuccess = (brandTypes) => ({
    type: BrandCategoriesTypes.SAVAN_FETCH_BRAND_CATEGORIES_SUCCESS,
    payload: brandTypes
})

export const fetchBrandCategoriesFailed = (error) => ({
    type: BrandCategoriesTypes.SAVAN_FETCH_BRAND_CATEGORIES_FAILED,
    payload: error
})
