import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as AppointmentsApi from '../../services/AppointmentsService'
import {
  fetchAppointmentsFailed,
  fetchAppointmentsStart,
  fetchAppointmentsSuccess,
  fetchAppointmentsCountFailed,
  fetchAppointmentsCountSuccess,
  saveAppointmentFailed,
  saveAppointmentSuccess,
  fetchAppointmentsHistorySuccess,
  fetchAppointmentsHistoryFailed,
  cancelAppointmentSuccess,
  cancelAppointmentFailed
} from '../actions/AppointmentsActions'
import AppointmentsTypes from '../types/AppointmentsTypes'
import AppTypes from "../types/AppTypes"
import { formToAppointment, sortAppointmentsByDate } from '../utils/AppointmentsUtils'
import { toast } from 'react-toastify'
import { selectDistributorId } from "../../redux/selectors/UserSelectors";
import { fetchDataStart } from "../actions/CalendarActions";

function* onFetchAppointments() {
  try {
    const params = {status:2}
    const idDistributor = yield select(selectDistributorId)
    const { data : {data} } = yield call(AppointmentsApi.fetchAppointments, idDistributor, params)
    const appointments = sortAppointmentsByDate(data)
    yield put(fetchAppointmentsSuccess(appointments))
  } catch (error) {
    yield put(fetchAppointmentsFailed(error))
  }
}

function* onFetchAppointmentsComing() {
  try {
    const params = {status:3}
    const idDistributor = yield select(selectDistributorId)
    const { data : {data} } = yield call(AppointmentsApi.fetchAppointments, idDistributor, params)
    const appointments = sortAppointmentsByDate(data)
    yield put(fetchAppointmentsSuccess(appointments))
  } catch (error) {
    yield put(fetchAppointmentsFailed(error))
  }
}

function* onFetchAppointmentsCount() {
  try {
    const params = {status:2}
    const idDistributor = yield select(selectDistributorId)
    const { data : {data} } = yield call(AppointmentsApi.fetchAppointmentsCount, idDistributor, params)
    const count = data["1"]
    yield put(fetchAppointmentsCountSuccess(count))
  } catch (error) {
    yield put(fetchAppointmentsCountFailed(error))
  }
}

function* fetchAppointmentsForHistory() {
    try {
        const idDistributor = yield select(selectDistributorId)
        const { data : {data} } = yield call(AppointmentsApi.fetchAppointments, idDistributor)
        const appointments = sortAppointmentsByDate(data, false)
        yield put(fetchAppointmentsHistorySuccess(appointments))
    } catch (error) {
        yield put(fetchAppointmentsHistoryFailed(error))
    }
}

function* onSaveAppointment({ payload }) {
  try {
    const { calendarView, ...rest } = payload
    const appointment = yield call(formToAppointment, rest)
    yield call(AppointmentsApi.updateAppointment, appointment.id, appointment)
    yield call(toast.success, "L'opération a réussi")
    yield put(saveAppointmentSuccess())
    if (!calendarView) {
      yield put(fetchAppointmentsStart())
    } else {
      yield put(fetchDataStart())
    }
  } catch (error) {
    yield call(toast.error, "L'opération a échoué " + error)
    yield put(saveAppointmentFailed(error))
  }
}

function* onCancelAppointment({ payload }) {
  try {
    const { id, note, calendarView } = payload
    yield call(AppointmentsApi.cancelAppointment, id, note)
    yield call(toast.success, "L'opération a réussi")
    yield put(cancelAppointmentSuccess())
    if (!calendarView) {
      yield put(fetchAppointmentsStart())
    } else {
      yield put(fetchDataStart())
    }
  } catch (error) {
    yield call(toast.error, "L'opération a échoué")
    yield put(cancelAppointmentFailed(error))
  }
}

const AppointmentsSagas = [
    takeLatest(AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_START, onFetchAppointments),
    takeLatest(AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_START, onFetchAppointmentsCount),
    takeLatest(AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COMING_START, onFetchAppointmentsComing),
    takeLatest(AppTypes.SAVAN_APP_INIT, onFetchAppointmentsCount),
    takeLatest(AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_HISTORY_START, fetchAppointmentsForHistory),
    takeLatest(AppointmentsTypes.SAVAN_SAVE_APPOINTMENT_START, onSaveAppointment),
    takeLatest(AppointmentsTypes.SAVAN_CANCEL_APPOINTMENT_START, onCancelAppointment),
]

export default AppointmentsSagas