import axios from 'axios'
import QueryString from 'qs'
import { setSession } from './auth'

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = `${process.env.REACT_APP_API_ROOT_URL}/api`
axiosInstance.defaults.headers.common['Accept'] = 'application/json, image/jpeg, image/jpg, image/png'

const responseErrorHandler = (error) => {
    if (
        error.response.status === 401 ||
        error.status === 401 ||
        error.response.status === 403 ||
        error.status === 403
    ) {
        setSession(null, null)
        window.location.href = '/login'
    }

    return Promise.reject(
        (error.response && error.response.data) || 'Something went wrong!'
    )
}

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => responseErrorHandler(error)
)

axiosInstance.interceptors.request.use(config => {
    config.paramsSerializer = params => {
      return QueryString.stringify(params, {
        arrayFormat: "brackets",
        encode: false
      });
    };

    return config;
  });

export default axiosInstance



