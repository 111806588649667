import ClientsTypes from "../types/ClientsTypes";

const searchParams = {
  isActive: true
}

const INITIAL_STATE = {
  list: [],
  isFetching: false,
  fetchingError: null,
  isSaving: false,
  savingError: null,
  searchParams
}

const ClientsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ClientsTypes.SAVAN_FETCH_CLIENTS_START:
      return { ...state, list: [], isFetching: true, fetchingError: null }
    case ClientsTypes.SAVAN_FETCH_CLIENTS_SUCCESS:
      return { ...state, list: payload, isFetching: false, fetchingError: null }
    case ClientsTypes.SAVAN_FETCH_CLIENTS_FAILED:
      return { ...state, list: [], isFetching: false, fetchingError: payload }
    case ClientsTypes.SAVAN_CLIENTS_FILTER_CHANGE:
      return { ...state, searchParams: payload }
    case ClientsTypes.SAVAN_SAVE_CLIENT_START:
      return { ...state, isSaving: true, savingError: null}
    case ClientsTypes.SAVAN_SAVE_CLIENT_SUCCESS:
      return { ...state, isSaving: false, savingError: null}
    case ClientsTypes.SAVAN_SAVE_CLIENT_FAILED:
      return { ...state, isSaving: false, savingError: payload}
    default:
      return state;
  }
}

export default ClientsReducer;
