import { call, put, takeLatest, select } from 'redux-saga/effects'
import ClientsTypes from "../types/ClientsTypes";
import * as ClientsActions from "../actions/ClientsActions";
import * as ClientsApi from '../../services/ClientsService'
import { selectClientsSearchParams } from "../selectors/ClientsSelectors";
import { selectDistributorId } from "../../redux/selectors/UserSelectors";
import { toast } from 'react-toastify';


function* onFetchClients() {
  try {
    const params = yield select(selectClientsSearchParams)
    const idDistributor = yield select(selectDistributorId)
    const { data: {data} } = yield call(ClientsApi.fetchClients, idDistributor, params)
    yield put(ClientsActions.fetchClientsSuccess(data))
  } catch (e) {
    yield put(ClientsActions.fetchClientsFailed(e))
  }
}

function* onFiltersChange() {
  yield put(ClientsActions.fetchClientsStart())
}

function* onSaveClient({ payload }) {
  try {
    const { id, ...rest } = payload
    if (!id || !Number.isInteger(id)) {
      yield call(ClientsApi.saveClient, rest)
    } else {
      yield call(ClientsApi.updateClient, id, rest)
    }
    yield call(toast.success, "L'opération a réussi")
    yield put(ClientsActions.saveClientSuccess())
    yield put(ClientsActions.fetchClientsStart())
  } catch (error) {
    yield call(toast.error, "L'opération a échoué")
    yield put(ClientsActions.saveClientFailed(error))
  }
}

const ClientsSagas = [
  takeLatest(ClientsTypes.SAVAN_FETCH_CLIENTS_START, onFetchClients),
  takeLatest(ClientsTypes.SAVAN_CLIENTS_FILTER_CHANGE, onFiltersChange),
  takeLatest(ClientsTypes.SAVAN_SAVE_CLIENT_START, onSaveClient),
]

export default ClientsSagas