import React from 'react'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/AuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { RootRoutes } from './RootRoutes'
import { ToastContainer } from 'react-toastify';
import { MessengerChat } from 'react-messenger-chat-plugin';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const routes = useRoutes(RootRoutes())

  return (
    <SettingsProvider>
      <MatxTheme>
        <AuthProvider>{routes}</AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
          theme='colored'
        />      
        <MessengerChat
            pageId="112256924816697"
            debugMode={true}
          />
      </MatxTheme>
    </SettingsProvider>
  )
}

export default App
