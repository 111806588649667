const AppointmentsTypes = {
    SAVAN_FETCH_APPOINTMENTS_START: 'SAVAN_FETCH_APPOINTMENTS_START',
    SAVAN_FETCH_APPOINTMENTS_COMING_START: 'SAVAN_FETCH_APPOINTMENTS_COMING_START',
    SAVAN_FETCH_APPOINTMENTS_SUCCESS: 'SAVAN_FETCH_APPOINTMENTS_SUCCESS',
    SAVAN_FETCH_APPOINTMENTS_FAILED: 'SAVAN_FETCH_APPOINTMENTS_FAILED',
    SAVAN_FETCH_APPOINTMENTS_COUNT_START: 'SAVAN_FETCH_APPOINTMENTS_COUNT_START',
    SAVAN_FETCH_APPOINTMENTS_COUNT_SUCCESS: 'SAVAN_FETCH_APPOINTMENTS_COUNT_SUCCESS',
    SAVAN_FETCH_APPOINTMENTS_COUNT_FAILED: 'SAVAN_FETCH_APPOINTMENTS_COUNT_FAILED',
    SAVAN_SAVE_APPOINTMENT_START: 'SAVAN_SAVE_APPOINTMENT_START',
    SAVAN_SAVE_APPOINTMENT_SUCCESS: 'SAVAN_SAVE_APPOINTMENT_SUCCESS',
    SAVAN_SAVE_APPOINTMENT_FAILED: 'SAVAN_SAVE_APPOINTMENT_FAILED',
    SAVAN_FETCH_APPOINTMENTS_HISTORY_START: 'SAVAN_FETCH_APPOINTMENTS_HISTORY_START',
    SAVAN_FETCH_APPOINTMENTS_HISTORY_SUCCESS: 'SAVAN_FETCH_APPOINTMENTS_HISTORY_SUCCESS',
    SAVAN_FETCH_APPOINTMENTS_HISTORY_FAILED: 'SAVAN_FETCH_APPOINTMENTS_HISTORY_FAILED',
    SAVAN_CANCEL_APPOINTMENT_START: 'SAVAN_CANCEL_APPOINTMENT_START',
    SAVAN_CANCEL_APPOINTMENT_SUCCESS: 'SAVAN_CANCEL_APPOINTMENT_SUCCESS',
    SAVAN_CANCEL_APPOINTMENT_FAILED: 'SAVAN_CANCEL_APPOINTMENT_FAILED',
}

export default AppointmentsTypes
