const CalendarTypes = {
  SAVAN_CALENDAR_CHANGE_DATE: 'SAVAN_CALENDAR_CHANGE_DATE',
  SAVAN_CALENDAR_CHANGE_VIEW: 'SAVAN_CALENDAR_CHANGE_VIEW',
  SAVAN_FETCH_CALENDAR_DATA_START: 'SAVAN_FETCH_CALENDAR_DATA_START',
  SAVAN_FETCH_CALENDAR_APPOINTMENTS_SUCCESS: 'SAVAN_FETCH_CALENDAR_APPOINTMENTS_SUCCESS',
  SAVAN_FETCH_CALENDAR_APPOINTMENTS_FAILED: 'SAVAN_FETCH_CALENDAR_APPOINTMENTS_FAILED',
  SAVAN_FETCH_CALENDAR_AVAILABILITIES_SUCCESS: 'SAVAN_FETCH_CALENDAR_AVAILABILITIES_SUCCESS',
  SAVAN_FETCH_CALENDAR_AVAILABILITIES_FAILED: 'SAVAN_FETCH_CALENDAR_AVAILABILITIES_FAILED',
}

export default CalendarTypes
