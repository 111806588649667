import { createSelector } from 'reselect'
import { get } from "lodash";

const selectUser = (state) => state.user

export const selectUserList = createSelector(
    selectUser,
    (user) => user.list
)

export const selectUserIsFetching = createSelector(
  selectUser,
  (user) => user.isFetching
)

export const selectFetchingUserFailed = createSelector(
  selectUser,
  (user) => !!user.fetchingError
)

export const selectIsSavingUser = createSelector(
    selectUser,
    (user) => user.isSaving
)



export const selectSavingUserError = createSelector(
    selectUser,
    (user) => user.savingError
)

export const selectSearchParams = createSelector(
  selectUser,
  (user) => user.searchParams
)

export const selectDistributorId = createSelector(
  selectUserList,
  user => get(user, 'distributors[0].id', null)
)
