import useAuth from 'app/hooks/useAuth'
import React, {useEffect, useState} from 'react'
import {Navigate, useLocation} from 'react-router-dom'

const AuthGuard = ({children}) => {
    const {pathname} = useLocation();
    const {isAuthenticated} = useAuth();
    const [previousRoute, setPreviousRoute] = useState(null);

    useEffect(() => {
        if (previousRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previousRoute])

    return (isAuthenticated) ? <>{children}</> : <Navigate to="/login" state={{redirectUrl: previousRoute}}/>;
}

export default AuthGuard
