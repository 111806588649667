import BrandCategoriesTypes from "../types/BrandCategoriesTypes"

const INITIAL_STATE = {
    list: [], isFetching: false, fetchingError: null
}

const BrandCategories = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BrandCategoriesTypes.SAVAN_FETCH_BRAND_CATEGORIES_START:
            return {...state, list: [], isFetching: true, fetchingError: null}
        case BrandCategoriesTypes.SAVAN_FETCH_BRAND_CATEGORIES_SUCCESS:
            return {...state, list: action.payload, isFetching: false, fetchingError: null}
        case BrandCategoriesTypes.SAVAN_FETCH_BRAND_CATEGORIES_FAILED:
            return {...state, list: [], isFetching: false, fetchingError: action.payload}
        default:
            return state
    }
}

export default BrandCategories