import CalendarTypes from '../types/CalendarTypes'
import { CALENDAR_VIEWS } from '../../utils/constant'
import { getDateInterval } from '../utils/CalendarUtils'

const today = new Date()
const { min, max } = getDateInterval(today, CALENDAR_VIEWS.WEEK)
const searchParams = {
    after: min,
    before: max,
    distributor: null
}

const INITIAL_STATE = {
    currentDate: today,
    currentView: CALENDAR_VIEWS.WEEK,
    appointments: {
        list: [],
        isFetching: false,
        fetchingError: null,
    },
    availabilities: {
        list: [],
        isFetching: false,
        fetchingError: null,
    },
    searchParams,
}

const CalendarReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case CalendarTypes.SAVAN_CALENDAR_CHANGE_DATE: {
            const { min, max } = getDateInterval(payload, state.currentView)
            return {
                ...state,
                currentDate: payload,
                searchParams: { after: min, before: max },
            }
        }
        case CalendarTypes.SAVAN_CALENDAR_CHANGE_VIEW: {
            const { min, max } = getDateInterval(state.currentDate, payload)
            return {
                ...state,
                currentView: payload,
                searchParams: { after: min, before: max },
            }
        }
        case CalendarTypes.SAVAN_FETCH_CALENDAR_DATA_START:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    list: [],
                    isFetching: true,
                    fetchingError: null,
                },
                availabilities: {
                    ...state.availabilities,
                    list: [],
                    isFetching: true,
                    fetchingError: null,
                },
            }
        case CalendarTypes.SAVAN_FETCH_CALENDAR_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    list: payload,
                    isFetching: false,
                    fetchingError: null,
                },
            }
        case CalendarTypes.SAVAN_FETCH_CALENDAR_APPOINTMENTS_FAILED:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    list: [],
                    isFetching: false,
                    fetchingError: payload,
                },
            }
        case CalendarTypes.SAVAN_FETCH_CALENDAR_AVAILABILITIES_SUCCESS:
            return {
                ...state,
                availabilities: {
                    ...state.availabilities,
                    list: payload,
                    isFetching: false,
                    fetchingError: null,
                },
            }
        case CalendarTypes.SAVAN_FETCH_CALENDAR_AVAILABILITIES_FAILED:
            return {
                ...state,
                availabilities: {
                    ...state.availabilities,
                    list: [],
                    isFetching: false,
                    fetchingError: payload,
                },
            }
        default:
            return state
    }
}

export default CalendarReducer
