import ClientsTypes from "../types/ClientsTypes";

export const fetchClientsStart = () => ({
    type: ClientsTypes.SAVAN_FETCH_CLIENTS_START
})

export const fetchClientsSuccess = (clients) => ({
    type: ClientsTypes.SAVAN_FETCH_CLIENTS_SUCCESS,
    payload: clients
})

export const fetchClientsFailed = (error) => ({
    type: ClientsTypes.SAVAN_FETCH_CLIENTS_FAILED,
    payload: error
})

export const changeSearchParams = (params) => ({
    type: ClientsTypes.SAVAN_CLIENTS_FILTER_CHANGE,
    payload: params
})

export const saveClientStart = (client) => ({
    type: ClientsTypes.SAVAN_SAVE_CLIENT_START,
    payload: client
})

export const saveClientSuccess = () => ({
    type: ClientsTypes.SAVAN_SAVE_CLIENT_SUCCESS,
})

export const saveClientFailed = (error) => ({
    type: ClientsTypes.SAVAN_SAVE_CLIENT_FAILED,
    payload: error
})
