import AppointmentsTypes from "../types/AppointmentsTypes";

export const fetchAppointmentsStart = (statusId) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_START,
    status: statusId
})

export const fetchAppointmentsComingStart = (statusId) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COMING_START,
    status: statusId
})

export const fetchAppointmentsSuccess = (appointments) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_SUCCESS,
    payload: appointments
})

export const fetchAppointmentsFailed = (error) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_FAILED,
    payload: error
})

export const fetchAppointmentsCountStart = () => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COUNT_START,
})

export const fetchAppointmentsCountSuccess = (count) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COUNT_SUCCESS,
    payload: count
})

export const fetchAppointmentsCountFailed = (error) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COUNT_FAILED,
    payload: error
})

export const saveAppointmentStart = (appointment) => ({
    type: AppointmentsTypes.SAVAN_SAVE_APPOINTMENT_START,
    payload: appointment
})

export const saveAppointmentSuccess = () => ({
    type: AppointmentsTypes.SAVAN_SAVE_APPOINTMENT_SUCCESS,
})

export const saveAppointmentFailed = (error) => ({
    type: AppointmentsTypes.SAVAN_SAVE_APPOINTMENT_FAILED,
    payload: error
})

export const fetchAppointmentsHistoryStart = () => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_HISTORY_START
})

export const fetchAppointmentsHistorySuccess = (appointments) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_HISTORY_SUCCESS,
    payload: appointments
})

export const fetchAppointmentsHistoryFailed = (error) => ({
    type: AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_HISTORY_FAILED,
    payload: error
})

export const cancelAppointmentStart = (appointment) => ({
    type: AppointmentsTypes.SAVAN_CANCEL_APPOINTMENT_START,
    payload: appointment
})

export const cancelAppointmentSuccess = () => ({
    type: AppointmentsTypes.SAVAN_CANCEL_APPOINTMENT_SUCCESS,
})

export const cancelAppointmentFailed = (error) => ({
    type: AppointmentsTypes.SAVAN_CANCEL_APPOINTMENT_FAILED,
    payload: error
})