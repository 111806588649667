import axios from "../utils/axios";

export const fetchUser = () => axios.get('/members/me')

export const updateDistributor = (id, data) => axios.put(`/distributors/${id}`, data)

// {
//     "id": 3,
//      "title": "test LOISIREO Albi",
//      "description": "test Loisiréo Albi sera votre partenaire de confiance dans votre vie de camping-cariste",
//      "address": "test 27 rue François Thermes – 81990 PUYGOUZON",
//      "postalCode": "909000",
//      "phoneNumber": "test 05 63 36 22 79",
//      "email": "testcontact@loisireo.fr",
//      "city": "City test",
//      "logo": "image_2022-06-30_102148887.png",
//      "banner": "image_2022-06-30_102150009.png",
//      "dealerGroup": "test dealer",
//      "horaires": "test horaries",
//      "website": "test web",
//      "facebookUrl": "test fb",
//      "twitterUrl": "test tw",
//      "linkedinUrl": "test linkedin"
// }