import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchUserSuccess, fetchUserFailed, fetchUserStart, saveDistributorFailed, saveDistributorSuccess } from '../actions/UserActions'
import * as UserApi from '../../services/UserService'
import UserTypes from '../types/UserTypes'
import AppTypes from '../types/AppTypes'
import { toast } from 'react-toastify';

function* onFetchUser() {
    try {
        const { data: { data } } = yield call(UserApi.fetchUser)
        yield put(fetchUserSuccess(data))
    } catch (error) {
        yield put(fetchUserFailed(error))
    }
}

function* onSaveDistributor({id,payload }) {
    try {
      yield call(UserApi.updateDistributor, id, payload)
      yield call(toast.success, "L'opération a réussi")
      yield put(saveDistributorSuccess())
      yield put(fetchUserStart())
    } catch (error) {
      yield call(toast.error, "L'opération a échoué")
      yield put(saveDistributorFailed(error))
    }
}

const UserSagas = [
    takeLatest(UserTypes.SAVAN_FETCH_User_START, onFetchUser),
    takeLatest(AppTypes.SAVAN_APP_INIT, onFetchUser),
    takeLatest(UserTypes.SAVAN_SAVE_DISTRIBUTOR_START, onSaveDistributor),
]

export default UserSagas
