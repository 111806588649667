import UserTypes from "../types/UserTypes";

const INITIAL_STATE = {
    list: [], 
    isFetching: false, 
    fetchingError: null,
    isSaving: false,
    savingError: null,
}

const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserTypes.SAVAN_FETCH_User_START:
            return {...state, list: [], isFetching: true, fetchingError: null}
        case UserTypes.SAVAN_FETCH_User_SUCCESS:
            return {...state, list: action.payload, isFetching: false, fetchingError: null}
        case UserTypes.SAVAN_FETCH_User_FAILED:
            return {...state, list: [], isFetching: false, fetchingError: action.payload}
        case UserTypes.SAVAN_SAVE_DISTRIBUTOR_START:
            return { ...state, isSaving: true, savingError: null }
        case UserTypes.SAVAN_SAVE_DISTRIBUTOR_SUCCESS:
            return { ...state, isSaving: false, savingError: null }
        case UserTypes.SAVAN_SAVE_DISTRIBUTOR_FAILED:
            return { ...state, isSaving: false, savingError: action.payload }        

        default:
            return state;
    }
}

export default UserReducer;