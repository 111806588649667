import { call, put, takeLatest } from 'redux-saga/effects'
import {
    fetchBrandCategoriesSuccess,
    fetchBrandCategoriesFailed,
} from '../actions/BrandCategoriesActions'
import * as BrandCategoriesApi from '../../services/BrandCategoriesService'
import BrandCategoriesTypes from '../types/BrandCategoriesTypes'
import AppTypes from '../types/AppTypes'

function* fetchBrandCategories() {
    try {
        const {
            data: { data },
        } = yield call(BrandCategoriesApi.fetchBrandCategories)
        yield put(fetchBrandCategoriesSuccess(data))
    } catch (error) {
        yield put(fetchBrandCategoriesFailed(error))
    }
}

const BrandCategoriesSagas = [
    takeLatest(BrandCategoriesTypes.SAVAN_FETCH_BRAND_CATEGORIES_START, fetchBrandCategories),
    takeLatest(AppTypes.SAVAN_APP_INIT, fetchBrandCategories),
]

export default BrandCategoriesSagas
