import AppointmentsTypes from '../types/AppointmentsTypes'
import { APPOINTMENT_STATUS } from "../../utils/constant";

const INITIAL_STATE = {
    list: [],
    isFetching: false,
    fetchingError: null,
    isSaving: false,
    savingError: null,
    searchParams: {
        status: APPOINTMENT_STATUS.PENDING
    },
    history: [],
    isFetchingHistory: false,
    fetchingHistoryError: null,
    count: null
}

const AppointmentsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_START:
            return { ...state, list: [], searchParams: {status: action.status}, isFetching: true, fetchingError: null }
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COMING_START:
                return { ...state, list: [], searchParams: {status: action.status}, isFetching: true, fetchingError: null }
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_SUCCESS:
            return { ...state, list: action.payload, isFetching: false, fetchingError: null}
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_FAILED:
            return { ...state, list: [], isFetching: false, fetchingError: action.payload}
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COUNT_START:
            return { ...state, count : null, isFetching: true, fetchingError: null }
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COUNT_SUCCESS:
            return { ...state, count : action.payload, isFetching: false, fetchingError: null}
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_COUNT_FAILED:
            return { ...state, count : null, isFetching: false, fetchingError: action.payload}
        case AppointmentsTypes.SAVAN_SAVE_APPOINTMENT_START:
        case AppointmentsTypes.SAVAN_CANCEL_APPOINTMENT_START:
            return { ...state, isSaving: true, savingError: null }
        case AppointmentsTypes.SAVAN_SAVE_APPOINTMENT_SUCCESS:
        case AppointmentsTypes.SAVAN_CANCEL_APPOINTMENT_SUCCESS:
            return { ...state, isSaving: false, savingError: null }
        case AppointmentsTypes.SAVAN_SAVE_APPOINTMENT_FAILED:
        case AppointmentsTypes.SAVAN_CANCEL_APPOINTMENT_FAILED:
            return { ...state, isSaving: false, savingError: action.payload }
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_HISTORY_START:
            return { ...state, history: [], isFetchingHistory: true, fetchingHistoryError: null }
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_HISTORY_SUCCESS:
            return { ...state, history: action.payload, isFetchingHistory: false, fetchingHistoryError: null }
        case AppointmentsTypes.SAVAN_FETCH_APPOINTMENTS_HISTORY_FAILED:
            return { ...state, history: [], isFetchingHistory: false, fetchingHistoryError: action.payload }
        default:
            return state
    }
}

export default AppointmentsReducer
