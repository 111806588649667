import { createSelector } from 'reselect'
import { getFutureAvailabilities } from "../utils/AvailabilitiesUtils";

const selectAvailabilities = (state) => state.availabilities

export const selectAvailabilitiesList = createSelector(
  [selectAvailabilities],
  (availabilities) => availabilities.list
)

export const selectIsFetchingAvailabilities = createSelector(
  selectAvailabilities,
  (availabilities) => availabilities.isFetching
)

export const selectFetchingAvailabilitiesFailed = createSelector(
  selectAvailabilities,
  (availabilities) => !!availabilities.fetchingError
)

export const selectFutureAvailabilities = createSelector(
  [selectAvailabilitiesList],
  (availabilities) => getFutureAvailabilities(availabilities)
)

export const selectSearchParams = createSelector(
  (selectAvailabilities),
  availabilities => availabilities.searchParams
)

export const selectIsDeletingAvailability = createSelector(
  selectAvailabilities,
  (availabilities) => availabilities.isDeleting
)

export const selectDeletingAvailabilityFailed = createSelector(
  selectAvailabilities,
  (availabilities) => !!availabilities.deletingError
)

export const selectIsSavingAvailability = createSelector(
  selectAvailabilities,
  (availabilities) => availabilities.isSaving
)

export const selectSavingAvailabilityFailed = createSelector(
  selectAvailabilities,
  (availabilities) => !!availabilities.savingError
)