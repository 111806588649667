const AvailabilitiesTypes = {
  SAVAN_FETCH_AVAILABILITIES_START: 'SAVAN_FETCH_AVAILABILITIES_START',
  SAVAN_FETCH_AVAILABILITIES_SUCCESS: 'SAVAN_FETCH_AVAILABILITIES_SUCCESS',
  SAVAN_FETCH_AVAILABILITIES_FAILED: 'SAVAN_FETCH_AVAILABILITIES_FAILED',
  SAVAN_DELETE_AVAILABILITY_START: 'SAVAN_DELETE_AVAILABILITY_START',
  SAVAN_DELETE_AVAILABILITY_SUCCESS: 'SAVAN_DELETE_AVAILABILITY_SUCCESS',
  SAVAN_DELETE_AVAILABILITY_FAILED: 'SAVAN_DELETE_AVAILABILITY_FAILED',
  SAVAN_SAVE_AVAILABILITY_START: 'SAVAN_SAVE_AVAILABILITY_START',
  SAVAN_SAVE_AVAILABILITY_SUCCESS: 'SAVAN_SAVE_AVAILABILITY_SUCCESS',
  SAVAN_SAVE_AVAILABILITY_FAILED: 'SAVAN_SAVE_AVAILABILITY_FAILED',
}

export default AvailabilitiesTypes
