import CalendarTypes from "../types/CalendarTypes";

export const changeCurrentDate = (date) => ({
  type: CalendarTypes.SAVAN_CALENDAR_CHANGE_DATE,
  payload: date
})

export const changeCurrentView = (view) => ({
  type: CalendarTypes.SAVAN_CALENDAR_CHANGE_VIEW,
  payload: view
})

export const fetchDataStart = () => ({
  type: CalendarTypes.SAVAN_FETCH_CALENDAR_DATA_START,
})

export const fetchAppointmentsSuccess = (data) => ({
  type: CalendarTypes.SAVAN_FETCH_CALENDAR_APPOINTMENTS_SUCCESS,
  payload: data
})

export const fetchAppointmentsFailed = (error) => ({
  type: CalendarTypes.SAVAN_FETCH_CALENDAR_APPOINTMENTS_FAILED,
  payload: error
})

export const fetchAvailabilitiesSuccess = (data) => ({
  type: CalendarTypes.SAVAN_FETCH_CALENDAR_AVAILABILITIES_SUCCESS,
  payload: data
})

export const fetchAvailabilitiesFailed = (error) => ({
  type: CalendarTypes.SAVAN_FETCH_CALENDAR_AVAILABILITIES_FAILED,
  payload: error
})