export const Navigations = [
    {
        name: 'Agenda',
        path: '/agenda',
        icon: 'calendar_month',
        role: ['ADMIN']
    },
    {
        name: 'À confirmer',
        path: '/a-confirmer',
        icon: 'event',
    },
    {        
        name: 'À venir',
        path: '/a-venir',
        icon: 'event_available',
    },
    {
        name: 'Clients',
        path: '/clients',
        icon: 'person',
    },
    {
        name: 'Historique',
        path: '/historique',
        icon: 'history',
    },
]
