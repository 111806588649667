import React from 'react'
import { Grid, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Box, styled } from '@mui/system'

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}))

const EndBox = styled(FlexBox)(() => ({
  justifyContent: 'flex-end',
  paddingTop: 40,
  paddingRight: 40
}))

const LogoBox = styled(JustifyBox)(() => ({
  width: 752,
  height: '100%',
}))

const IMG = styled('img')(() => ({
  width: '100%',
}))

const Home = styled(JustifyBox)(() => ({
  backgroundImage: 'url(/assets/images/savan/homeBackground.png)',
  backgroundSize: 'cover',
  height: '100%',
}))

const ShadowBox = styled(JustifyBox)(() => ({
  height: '100%',
  width: '100%',
  background: 'rgba(0, 0, 0, 0.5)'
}))

const ConnectionButton = styled(Button)(() => ({
  textTransform: 'uppercase',
  background: 'linear-gradient(90deg, #6FEEFE 0%, #01FFB7 100%)',
  width: 160,
  borderRadius: 0,
  ':hover': {
    background: 'transparent',
  }
}))

const GridHeader = styled(Grid)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%'
}))

const HomePage = () => {
  const navigate = useNavigate()

  const handleClickConnection = () => {
    navigate('/login')
  }

  return (
    <Home>

      <ShadowBox>
        <Grid container>
          <GridHeader item xs={12}>
            <EndBox>
              <ConnectionButton
                disableElevation
                variant="contained"
                type="button"
                onClick={handleClickConnection}
              >
                Connexion
              </ConnectionButton>
            </EndBox>
          </GridHeader>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <LogoBox p={4}>
              <IMG
                src="/assets/images/savan/logo-nobackground.png"
                alt="logo"
              />
            </LogoBox>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Typography sx={{ width: 614, color: '#fff', textAlign: 'center', fontSize: 18 }}>
              La solution digitale spécialisée véhicules de loisirs pour la gestion et la prise de rendez-vous SAV.
            </Typography>
          </Grid>
        </Grid>
      </ShadowBox>
    </Home>
  )
}

export default HomePage
