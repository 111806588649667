import React, {createContext, useEffect, useReducer} from 'react'
import axios from '../utils/axios'
import {MatxLoading} from 'app/components'
import LoginReducer from "../redux/reducers/LoginReducer";
import { isValidToken, setSession } from "../utils/auth";
import {memberLoginInit, memberLoginSuccess, memberLogoutSuccess} from "../redux/actions/LoginActions";

const initialState = {
    isAuthenticated: false, isInitialised: false, accessToken: null, user: null, authError: null,
}

const AuthContext = createContext({
    ...initialState, method: 'JWT', login: () => Promise.resolve(), logout: () => {
    },
})

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(LoginReducer, initialState)

    const login = async (email, password) => {
        const response = await axios.post('/members/login', {
            username: email, password
        });

        const {data: {member, token}} = response.data

        setSession(token, member);
        dispatch(memberLoginSuccess(token, member));
    }

    const logout = () => {
        setSession(null, null)
        dispatch(memberLogoutSuccess());
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const user = JSON.parse(window.localStorage.getItem('currentUser'))

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken, user)
                    dispatch(memberLoginInit(true, user));
                } else {
                    dispatch(memberLoginInit(false, null));
                }
            } catch (err) {
                dispatch(memberLoginInit(false, null));
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading/>
    }

    return (<AuthContext.Provider value={{...state, method: 'JWT', login, logout}}>
        {children}
    </AuthContext.Provider>)
}

export default AuthContext
