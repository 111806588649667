import { createSelector } from "reselect";
import { transformClients } from "../utils/ClientsUtils";

const selectClients = state => state.clients

export const selectClientsList = createSelector(
  selectClients,
  clients => transformClients(clients.list)
)

export const selectClientsSearchParams = createSelector(
  selectClients,
  clients => clients.searchParams
)

export const selectIsFetchingClients = createSelector(
  selectClients,
  clients => clients.isFetching
)

export const selectFetchingClientsFailed = createSelector(
  selectClients,
  clients => !!clients.fetchingError
)

export const selectIsSavingClient = createSelector(
  selectClients,
  clients => clients.isSaving
)

export const selectSavingClientFailed = createSelector(
  selectClients,
  clients => !!clients.savingError
)