import {
  format,
  startOfDay,
  addHours,
  subMinutes,
  endOfDay,
  isFuture,
  addMonths,
  isAfter,
  isBefore,
  subHours
} from "date-fns";
import { sortBy } from "lodash";
import fr from "date-fns/locale/fr";
import capitalize from "lodash/capitalize";
import { getDateWithoutTimeZone, getDateWithTimeZone } from "../../utils/utils";

// Display the slots available in a future horizon of 3 months.
const DATE_RANGE = 3

//asc
export function sortAvailabilitiesByDate(availabilities) {
  if (!Array.isArray(availabilities)) return []
  return sortBy(availabilities, ['dayDispo'])
}

export function getFutureAvailabilities (availabilities) {
  if (!Array.isArray(availabilities)) return []
  const startDateRange = new Date()
  const endDateRange = endOfDay(addMonths(startDateRange, DATE_RANGE))
  const _availabilities = []
  sortAvailabilitiesByDate(availabilities).forEach((availability) => {
    const {dayDispo, dayPeriod, appointments} = availability
    const availabilityDate = getDateWithoutTimeZone(dayDispo)
    if (isAfter(availabilityDate, startDateRange) && isBefore(availabilityDate, endDateRange) && isFuture(availabilityDate) && !appointments) {
      const day = format(availabilityDate, 'EEEE d', { locale: fr })
      const month = format(availabilityDate, 'MMMM', { locale: fr })
      const label = `${capitalize(day)} ${capitalize(month)} (${dayPeriod === 'AM' ? 'Matin' : 'Après midi'})`
      const startHour = dayPeriod === 'AM' ? addHours(startOfDay(availabilityDate), 8) : addHours(startOfDay(availabilityDate), 12)
      const endHour = dayPeriod === 'AM' ? subMinutes(addHours(startHour, 4), 1) : subHours(endOfDay(availabilityDate), 4)
      _availabilities.push({
          ...availability,
        date: availabilityDate,
        label,
        startHour,
        endHour
      })
    }
  })
  return _availabilities
}

export function formToAvailability(form) {
  const { weekDays, dayDispoStart, dayDispoEnd, hourDispoStart, hourDispoEnd } = form
  const selectedDays = weekDays.filter(day => day.selected).map(day => day.value)
  return {
    ...form,
    dayDispoStart: getDateWithTimeZone(dayDispoStart),
    dayDispoEnd: getDateWithTimeZone(dayDispoEnd),
    hourDispoStart: getDateWithTimeZone(hourDispoStart),
    hourDispoEnd: getDateWithTimeZone(hourDispoEnd),
    weekDays: selectedDays
  }
}