import BrandsTypes from "../types/BrandsTypes";

export const fetchBrandsStart = () => ({
    type: BrandsTypes.SAVAN_FETCH_BRANDS_START
})

export const fetchBrandsSuccess = (brands) => ({
    type: BrandsTypes.SAVAN_FETCH_BRANDS_SUCCESS,
    payload: brands
})

export const fetchBrandsFailed = (error) => ({
    type: BrandsTypes.SAVAN_FETCH_BRANDS_FAILED,
    payload: error
})
