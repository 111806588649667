import jwtDecode from "jwt-decode";
import axios from "./axios";

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }

  const decodedToken = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000
  return decodedToken.exp > currentTime
}

export const setSession = (accessToken, user) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('currentUser', JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
    delete axios.defaults.headers.common.Authorization;
  }
}
