import { call, put, takeLatest } from 'redux-saga/effects'
import {
    fetchServicesSuccess,
    fetchServicesFailed,
} from '../actions/ServicesActions'
import * as ServicesApi from '../../services/ServicesService'
import ServicesTypes from '../types/ServicesTypes'
import AppTypes from '../types/AppTypes'

function* fetchServices() {
    try {
        const {data} = yield call(ServicesApi.fetchServices)
        yield put(fetchServicesSuccess(data))
    } catch (error) {
        yield put(fetchServicesFailed(error))
    }
}

const ServicesSagas = [
    takeLatest(ServicesTypes.SAVAN_FETCH_SERVICES_START, fetchServices),
    takeLatest(AppTypes.SAVAN_APP_INIT, fetchServices),
]

export default ServicesSagas
