import ServicesTypes from "../types/ServicesTypes";

const INITIAL_STATE = {
    list: [], isFetching: false, fetchingError: null
}

const ServicesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ServicesTypes.SAVAN_FETCH_SERVICES_START:
            return {...state, list: [], isFetching: true, fetchingError: null}
        case ServicesTypes.SAVAN_FETCH_SERVICES_SUCCESS:
            return {...state, list: action.payload, isFetching: false, fetchingError: null}
        case ServicesTypes.SAVAN_FETCH_SERVICES_FAILED:
            return {...state, list: [], isFetching: false, fetchingError: action.payload}
        default:
            return state;
    }
}

export default ServicesReducer;
