import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as AvailabilitiesActions from '../actions/AvailabilitiesActions'
import * as AvailabilitiesApi from '../../services/AvailabilitiesService'
import AvailabilitiesTypes from '../types/AvailabilitiesTypes'
import { toast } from 'react-toastify';
import { selectSearchParams } from "../selectors/AvailabilitiesSelectors";
import { formToAvailability } from "../utils/AvailabilitiesUtils";

function* fetchAvailabilities() {
  try {
    const params = yield select(selectSearchParams)
    const { data } = yield call(AvailabilitiesApi.fetchAvailabilities, params)
    yield put(AvailabilitiesActions.fetchAvailabilitiesSuccess(data))
  } catch (error) {
    yield put(AvailabilitiesActions.fetchAvailabilitiesFailed(error))
  }
}

function* onDeleteAvailability({ payload }) {
  try {
    yield call(AvailabilitiesApi.deleteAvailability, payload)
    yield call(toast.success, "L'opération a réussi")
    yield put(AvailabilitiesActions.deleteAvailabilitySuccess())
  } catch (error) {
    yield call(toast.error, "L'opération a échoué")
    yield put(AvailabilitiesActions.deleteAvailabilityFailed(error))
  }
}

function* onSaveAvailability({ payload }) {
  try {
    const { id } = payload
    const availability = formToAvailability(payload)
    if (!id || !Number.isInteger(id)) {
      yield call(AvailabilitiesApi.saveAvailability, availability)
    } else {
      yield call(AvailabilitiesApi.updateAvailability, id, availability)
    }
    yield call(toast.success, "L'opération a réussi")
    yield put(AvailabilitiesActions.saveAvailabilitySuccess())
  } catch (error) {
    yield call(toast.error, "L'opération a échoué")
    yield put(AvailabilitiesActions.saveAvailabilityFailed(error))
  }
}

const AvailabilitiesSagas = [
  takeLatest(AvailabilitiesTypes.SAVAN_FETCH_AVAILABILITIES_START, fetchAvailabilities),
  takeLatest(AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_START, onDeleteAvailability),
  takeLatest(AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_START, onSaveAvailability),
]

export default AvailabilitiesSagas
