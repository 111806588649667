import React, {lazy} from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const AuthLogin = Loadable(lazy(() => import('./login/LoginPage')))
const AuthForgotPassword = Loadable(lazy(() => import('./forgot-password/ForgotPasswordPage')))
const AuthChangePassword = Loadable(lazy(() => import('./forgot-password/ChangePasswordPage')))
const AuthActivateAccount = Loadable(lazy(() => import('./forgot-password/ActivateAccountPage')))

export const AuthRoutes = [
    {
        path: '/login',
        element: <AuthLogin/>,
    },
    {
        path: '/forgot-password',
        element: <AuthForgotPassword/>,
    },   
    {
        path: '/change-password',
        element: <AuthChangePassword/>,
    },  
    {
        path: '/activate-account',
        element: <AuthActivateAccount/>,
    },
];
