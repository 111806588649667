import axios from "../utils/axios";

export const fetchAvailabilities = (params = null) => axios.get('/disponibilities', {
  params: {...params}
})

export const saveAvailability = (availability) => axios.post(`/disponibilities`, { ...availability })

export const updateAvailability = (id, availability) => axios.put(`/disponibilities/${id}`, { ...availability })

export const deleteAvailability = (id) => axios.delete(`/disponibilities/${id}`)
