import AvailabilitiesTypes from "../types/AvailabilitiesTypes";

const searchParams = {
  distributor: null
}

const INITIAL_STATE = {
  list: [],
  isFetching: false,
  fetchingError: null,
  isDeleting: false,
  deletingError: null,
  searchParams,
  isSaving: false,
  savingError: null,
}

const AvailabilitiesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AvailabilitiesTypes.SAVAN_FETCH_AVAILABILITIES_START:
      return { ...state, list: [], isFetching: true, fetchingError: null, searchParams: payload }
    case AvailabilitiesTypes.SAVAN_FETCH_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        list: payload,
        isFetching: false,
        fetchingError: null,
      }
    case AvailabilitiesTypes.SAVAN_FETCH_AVAILABILITIES_FAILED:
      return {
        ...state,
        list: [],
        isFetching: false,
        fetchingError: payload,
      }
    case AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_START:
      return { ...state, isDeleting: true, deletingError: null }
    case AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_SUCCESS:
      return { ...state, isDeleting: false, deletingError: null }
    case AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_FAILED:
      return { ...state, isDeleting: false, deletingError: payload }
    case AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_START:
      return { ...state, isSaving: true, savingError: null }
    case AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_SUCCESS:
      return { ...state, isSaving: false, savingError: null }
    case AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_FAILED:
      return { ...state, isSaving: false, savingError: payload }
    default:
      return state;
  }
}

export default AvailabilitiesReducer