export const topBarHeight = 64
export const sideNavWidth = 230
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

export const PRIORITY_TYPES = {
  RETARD: 'RETARD',
  URGENT: 'URGENT',
  NORMAL: 'NORMAL',
  LOW: 'LOW',
  RECENT : 'RECENT',
  OLD: 'OLD',

}

export const APPOINTMENTS_PRIORITY = [
  { value: 1, type: PRIORITY_TYPES.RETARD, label: 'rendez-vous en retard (non traité)' },
  { value: 2, type: PRIORITY_TYPES.URGENT, label: 'urgent (moins de 2 jours)' },
  { value: 3, type: PRIORITY_TYPES.NORMAL, label: 'à traiter bientôt (entre 2 et 5 jours)' },
  { value: 4, type: PRIORITY_TYPES.LOW, label: 'moins urgent (plus de 5 jours)' },
  { value: 5, type: PRIORITY_TYPES.OLD, label: 'demandes anciennes (plus de 2 jours)' },
  { value: 6, type: PRIORITY_TYPES.RECENT, label: 'demandes récentes (moins de 2 jours)' },
]

export const APPOINTMENTS_PRIORITY_COLOR = {
  [PRIORITY_TYPES.URGENT]: '#FA274D',
  [PRIORITY_TYPES.NORMAL]: '#FA8C27',
  [PRIORITY_TYPES.LOW]: '#666666',
  [PRIORITY_TYPES.RETARD]: '#FAD27D',
  [PRIORITY_TYPES.RECENT]: '#666666',
  [PRIORITY_TYPES.OLD]: '#FA274D'
}

export const BRAND_TYPES = {
  CARRIER: 'porteur',
  CELL: 'cellule',
  HEATING: 'chauffage',
  WATER_HEATER: 'chauffe-eau',
  REFRIGERATOR: 'réfrigérateur',
  WARRANTY: 'assurance'
}

export const SERVICE_TYPES = {
  CARRIER: 'Porteur',
  CELL: 'Cellule',
}

export const SERVICES = [
  { value: 2, type: SERVICE_TYPES.CARRIER },
  { value: 3, type: SERVICE_TYPES.CELL },
]

export const CARRIER_SERVICE = {
  value: 2,
  label: SERVICE_TYPES.CARRIER
}

export const CELL_SERVICE = {
  value: 3,
  label: SERVICE_TYPES.CELL
}

export const TIME_VALUES_OPTIONS = Array(11).fill(0).map((x, i) => {
  const value = i / 2 + 1;
  let label = ''
  if (i % 2 === 0) {
    if (value < 9) {
      label = `0${value} - 00 MIN`
    } else {
      label = `${value} - 00 MIN`
    }
  } else {
    if(value < 9) {
      label = `0${Math.floor(value)} - 30 MIN`
    } else {
      label = `${Math.floor(value)} - 30 MIN`
    }
  }
  return {
    value,
    label
  }
});

TIME_VALUES_OPTIONS.unshift(
  { value: 0.5, label: '00 - 30 MIN' }
)

export const APPOINTMENT_STATUS = {
  PENDING: 2,
  CONFIRMED: 3,
  CANCELED: 4,

}

export const CALENDAR_VIEWS = {
  WEEK: "Week",
  MONTH: "Month"
}

export const LOCALE_CALENDAR_VIEWS = {
  WEEK: "Semaine",
  MONTH: "Mois"
}

export const CLIENTS_FILTER_OPTIONS = [
  {label: 'ACTIFS', value: 1, isActive: true},
  {label: 'ARCHIVÉS', value: 2, isActive: false},
]

export const AVAILABILITY_DAYS = [
  {value: 1, label: 'L', selected: false},
  {value: 2, label: 'M', selected: false},
  {value: 3, label: 'M', selected: false},
  {value: 4, label: 'J', selected: false},
  {value: 5, label: 'V', selected: false},
  {value: 6, label: 'S', selected: false},
  {value: 7, label: 'D', selected: false},
]
