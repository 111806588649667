import React from 'react'
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'
import App from './app/App'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import * as serviceWorker from './serviceWorker'
import { StyledEngineProvider } from '@mui/styled-engine'
import { CssBaseline } from '@mui/material'
import { store, persistor } from './app/redux/Store'
import Loading from "./app/components/MatxLoading/MatxLoading";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css';


Sentry.init({
  dsn: "https://3218e6ce759141ec899559e3ff613f96@o1419160.ingest.sentry.io/4504481263517696",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <BrowserRouter>
            <CssBaseline />
            <PersistGate persistor={persistor} loading={<Loading />}>
              <App />
            </PersistGate>
        </BrowserRouter>
      </Provider>
    </StyledEngineProvider>,
    document.getElementById('root')
)

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
