import { createSelector } from 'reselect'
import { groupAppointmentsByPriority, transformAppointmentsServices } from '../utils/AppointmentsUtils'
import { selectServicesList } from './ServicesSelectors'

const selectAppointments = (state) => state.appointments
const appointmentsCount = (state) => state.appointments.count

export const selectAppointmentsList = createSelector(
  selectAppointments,
  (appointments) => appointments.list
)

export const selectAppointmentsHistory = createSelector(
  selectAppointments,
  (appointments) => appointments.history
)

export const selectAppointmentsGroupedByPriority = createSelector(
    [selectAppointmentsList, selectServicesList],
    (appointments, services) => groupAppointmentsByPriority(appointments, services)
)

export const selectIsFetchingAppointments = createSelector(
    selectAppointments,
    (appointments) => appointments.isFetching
)
export const selectFetchingAppointmentsFailed = createSelector(
    selectAppointments,
    (appointments) => !!appointments.fetchingError
)

export const selectAppointmentsTransformed = createSelector(
    [selectAppointmentsHistory, selectServicesList],
    (appointments, services) => transformAppointmentsServices(appointments, services, true)
)

export const selectIsFetchingAppointmentsHistory = createSelector(
  selectAppointments,
  (appointments) => appointments.isFetchingHistory
)
export const selectFetchingAppointmentsHistoryFailed = createSelector(
  selectAppointments,
  (appointments) => !!appointments.fetchingHistoryError
)

export const selectAppointmentsCount = createSelector(
  appointmentsCount,
  (count) => count
)

export const selectIsSavingAppointment = createSelector(
    selectAppointments,
    (appointments) => appointments.isSaving
)

export const selectSavingAppointmentError = createSelector(
    selectAppointments,
    (appointments) => appointments.savingError
)

export const selectSearchParams = createSelector(
  selectAppointments,
  (appointments) => appointments.searchParams
)

