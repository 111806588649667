import {
  addHours,
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  getHours,
  getMinutes,
  isToday,
  startOfDay,
  addMinutes
} from 'date-fns'
import capitalize from 'lodash/capitalize'
import fr from 'date-fns/locale/fr'
import { get, isString } from 'lodash'
import { APPOINTMENT_STATUS, PRIORITY_TYPES } from 'app/utils/constant'
import { getDateWithoutTimeZone, getDateWithTimeZone } from "../../utils/utils";

const DEFAULT_USER = {
  firstName: 'john',
  lastName: 'doe',
  email: 'john.doe@gmail.com',
  phoneNumber: ' +33612345678',
  address: ''
}

// sort asc
export function sortAppointmentsByDate(appointments, asc = true) {
  if (Array.isArray(appointments)) {
    if (asc) {
      return appointments.sort((a, b) =>
        a.startDateTime > b.startDateTime ? 1 : -1
      )
    } else {
      return appointments.sort((a, b) =>
        a.startDateTime > b.startDateTime ? -1 : 1
      )
    }
  }
  return []
}

export function transformAppointments(appointments) {
  if (!Array.isArray(appointments)) return []
  return appointments.map((appointment) => transformAppointment(appointment))
}


export function transformAppointmentsServices(appointments, services, historyView = false) {
  if (!Array.isArray(appointments)) return []
  return appointments.map((appointment) => transformAppointment(appointment, services, historyView))
}

export function groupAppointmentsByPriority(appointments, allServices) {
  const groupedAppointments = {
    [PRIORITY_TYPES.RETARD]: [],
    [PRIORITY_TYPES.URGENT]: [],
    [PRIORITY_TYPES.NORMAL]: [],
    [PRIORITY_TYPES.LOW]: [],
    [PRIORITY_TYPES.OLD]: [],
    [PRIORITY_TYPES.RECENT]: [],
    length: appointments.length
  }
  const today = new Date()
  if (!Array.isArray(appointments)) return []
  appointments.forEach((appointment) => {
    let { createdAt, startDateTime, services, isLead, status } = appointment
    const serviceType =
      Array.isArray(services) && services.length > 0
        ? services[0].category.id
        : 1

    let time = null

    if(isLead === true){
      if(status.id === 2){
        time = getDateWithoutTimeZone(createdAt)
      }else{
        time = getDateWithoutTimeZone(startDateTime)
      }
    }else{
      time = getDateWithoutTimeZone(startDateTime)
    }
    
    const difference =
      isToday(time) &&
      differenceInMinutes(time, today) > 0
        ? 1
        : differenceInCalendarDays(time, today)

    console.log(isLead + " - " + createdAt + " || " + startDateTime + " || " + difference + " || " + time)
    if(isLead !== true){
      if(difference < 0 && serviceType !== 1){
        groupedAppointments[PRIORITY_TYPES.RETARD].push(
          transformAppointment(appointment, allServices)
        )
      }
      if (difference >= 0 && serviceType !== 1) {
        if (difference < 2) {
          groupedAppointments[PRIORITY_TYPES.URGENT].push(
            transformAppointment(appointment, allServices)
          )
        } else if (difference >= 2 && difference <= 5) {
          groupedAppointments[PRIORITY_TYPES.NORMAL].push(
            transformAppointment(appointment, allServices)
          )
        } else {
          groupedAppointments[PRIORITY_TYPES.LOW].push(
            transformAppointment(appointment, allServices)
          )
        }
      }
    }else{
      if(status.id === 2){
        if (serviceType !== 1) {
          if (difference > -2) {
            groupedAppointments[PRIORITY_TYPES.RECENT].push(
              transformAppointment(appointment, allServices)
            )
          } else {
            groupedAppointments[PRIORITY_TYPES.OLD].push(
              transformAppointment(appointment, allServices)
            )
          }
        }
      }else{
        if (serviceType !== 1) {
          if (difference < 0) {
            groupedAppointments[PRIORITY_TYPES.RETARD].push(
              transformAppointment(appointment, allServices)
            )
          } else if (difference < 2) {
            groupedAppointments[PRIORITY_TYPES.URGENT].push(
              transformAppointment(appointment, allServices)
            )
          } else if (difference >= 2 && difference <= 5) {
            groupedAppointments[PRIORITY_TYPES.NORMAL].push(
              transformAppointment(appointment, allServices)
            )
          } else {
            groupedAppointments[PRIORITY_TYPES.LOW].push(
              transformAppointment(appointment, allServices)
            )
          }
        }
      }
    }
  })
  return groupedAppointments
}

export function transformAppointment(appointment, allServices, historyView = false) {
  const {
    startDateTime,
    endDateTime,
    note,
    vehicle,
    services,
    disponibility,
    createdAt,
    images,
    status,
    ...rest
  } = appointment

  const brands = get(vehicle, 'brands', [])
  const user = get(vehicle, 'user', DEFAULT_USER)
  const firstName = get(user, 'firstName', '')
  const lastName = get(user, 'lastName', '')
  const phoneNumber = get(user, 'phoneNumber', '')
  const dayPeriod = get(disponibility, 'dayPeriod', 'AM')
  const interventionTime = get(appointment, 'interventionTime', 0.5)


  const startDate = getDateWithoutTimeZone(startDateTime)
  const endDate = addMinutes(startDate, interventionTime * 60)
  let fullDate = !historyView ? getDateWithoutTimeZone(createdAt) : startDate
  let createdAtDate = getDateWithoutTimeZone(createdAt)
  const day = format(startDate, 'EEEE d', { locale: fr })
  const month = format(startDate, 'MMMM', { locale: fr })
  const stringDate = `${capitalize(day)} ${capitalize(month)} (${dayPeriod === 'AM' ? 'Matin' : 'Après-midi'})`
  fullDate = format(fullDate, 'dd/MM/y', { locale: fr })
  createdAtDate = format(createdAtDate, 'dd/MM/y', { locale: fr })
  //TODO: Check if startTime and endTime fields are used
  const startTime = `${getHours(startDate)} H - ${getMinutes(startDate)} MIN`
  const endTime = `${getHours(endDate)} H - ${getMinutes(endDate)} MIN`
  const serviceType =
    Array.isArray(services) && services.length > 0
      ? services[0].category.id
      : 2
  const servicesList =
    serviceType !== 1
      ? transformAppointmentServices(allServices, services)
      : []

  const serializedBrands = {}
  brands.forEach(({ id, brandType: { name } }) => {
    serializedBrands[name.toLocaleLowerCase()] = id
  })

  const phone = transformPhoneNumber(phoneNumber)
  
  const startHour = dayPeriod === 'AM' ? addHours(startOfDay(startDate), 6) : addHours(startOfDay(startDate), 12)
  const endHour = dayPeriod === 'AM' ? addHours(startHour, 6) : addHours(startHour, 8)
 
  let appointmentImages = [
    {label: 'photo 1.jpg'},
    {label: 'photo 2.jpg'},
    {label: 'photo 3.jpg'},
  ]

  appointmentImages = appointmentImages.map((image, index) => ({
    ...image, value: images[index] ? images[index] : null
  }))

  return {
    ...rest,
    startDate,
    endDate,
    interventionTime,
    stringDate,
    createdAtDate,
    fullDate,
    title: `${firstName} ${lastName}`.toUpperCase(),
    note,
    vehicle: {
      ...vehicle,
      user: {
        ...user,
        phone
      }
    },
    client: `${firstName} ${lastName}`.toUpperCase(),
    services: servicesList,
    serviceType,
    startTime,
    endTime,
    brands: serializedBrands,
    disponibility,
    startOfDay: startHour,
    endOfDay: endHour,
    images: appointmentImages,
    status,
    pending: status.id === APPOINTMENT_STATUS.PENDING
  }
}

export function transformAppointmentServices(allServices, appointmentServices) {
  const serviceType =
    Array.isArray(appointmentServices) && appointmentServices.length > 0
      ? appointmentServices[0].category.id
      : 2
  if (!Array.isArray(allServices) || !Array.isArray(appointmentServices))
    return []
  return allServices
    .filter((item) => item.category.id === serviceType)
    .map((service) => {
      const found = appointmentServices.find(
        (appService) => appService.id === service.id
      )
      return found
        ? {
          ...found,
          selected: true,
          fullName: found.name,
          name: found.name.slice(0, found.name.indexOf('(')).trim(),
        }
        : {
          ...service,
          selected: false,
          fullName: service.name,
          name: service.name
            .slice(0, service.name.indexOf('('))
            .trim(),
        }
    })
}

export function formToAppointment(form) {
  const {
    id,
    startDate,
    interventionTime,
    services,
    reparationType,
    statusId,
    note,
    isWarranty,
    wantsToSell,
    alreadyClient,
    vehicle,
    disponibility,
    workLength,
    brands,
    isLead
  } = form

  const servicesId = services.filter(service => service.selected).map(service => service.id)
  const vehicleId = vehicle.id
  const disponibilityId = isLead ? null : disponibility.id
  const brandsId = Object.values(brands)

  return {
    id,
    note,
    reparationType,
    isWarranty,
    wantsToSell,
    alreadyClient,
    vehicleId,
    servicesId,
    brandsId,
    disponibilityId,
    statusId,
    startDateTime: getDateWithTimeZone(startDate),
    interventionTime,
    workLength,
    isLead
  }
}

export function transformPhoneNumber(phone) {
  if (!isString(phone)) return ''
  let formatPhone = `0${phone.substring(phone.length - 9)}`
  formatPhone = formatPhone.match(/.{1,2}/g)
  return formatPhone.join('.')
}