import BrandsTypes from "../types/BrandsTypes";

const INITIAL_STATE = {
    list: [], isFetching: false, fetchingError: null
}

const BrandsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BrandsTypes.SAVAN_FETCH_BRANDS_START:
            return {...state, list: [], isFetching: true, fetchingError: null}
        case BrandsTypes.SAVAN_FETCH_BRANDS_SUCCESS:
            return {...state, list: action.payload, isFetching: false, fetchingError: null}
        case BrandsTypes.SAVAN_FETCH_BRANDS_FAILED:
            return {...state, list: [], isFetching: false, fetchingError: action.payload}
        default:
            return state;
    }
}

export default BrandsReducer;
