import UserTypes from "../types/UserTypes";

export const fetchUserStart = () => ({
    type: UserTypes.SAVAN_FETCH_User_START
})

export const fetchUserSuccess = (user) => ({
    type: UserTypes.SAVAN_FETCH_User_SUCCESS,
    payload: user
})

export const fetchUserFailed = (error) => ({
    type: UserTypes.SAVAN_FETCH_User_FAILED,
    payload: error
})

export const saveDistributorStart = (id, distributor) => ({
    type: UserTypes.SAVAN_SAVE_DISTRIBUTOR_START,
    id: id,
    payload: distributor
})

export const saveDistributorSuccess = () => ({
    type: UserTypes.SAVAN_SAVE_DISTRIBUTOR_SUCCESS,
})

export const saveDistributorFailed = (error) => ({
    type: UserTypes.SAVAN_SAVE_DISTRIBUTOR_FAILED,
    payload: error
})