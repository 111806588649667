import {Navigations} from 'app/Navigations'

const initialState = [...Navigations]

const NavigationReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'SAVAN_SET_USER_NAVIGATION': {
            return [...action.payload]
        }
        default: {
            return [...state]
        }
    }
}

export default NavigationReducer
