import ServicesTypes from "../types/ServicesTypes";

export const fetchServicesStart = () => ({
    type: ServicesTypes.SAVAN_FETCH_SERVICES_START
})

export const fetchServicesSuccess = (services) => ({
    type: ServicesTypes.SAVAN_FETCH_SERVICES_SUCCESS,
    payload: services
})

export const fetchServicesFailed = (error) => ({
    type: ServicesTypes.SAVAN_FETCH_SERVICES_FAILED,
    payload: error
})
