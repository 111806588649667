import { all } from 'redux-saga/effects'
import AppointmentsSagas from './sagas/AppointmentsSagas'
import BrandCategoriesSagas from './sagas/BrandCategoriesSagas'
import ServicesSagas from './sagas/ServicesSagas'
import BrandsSagas from './sagas/BrandsSagas'
import UserSagas from './sagas/UserSagas'
import AvailabilitiesSagas from './sagas/AvailabilitiesSagas'
import CalendarSagas from './sagas/CalendarSagas'
import ClientsSagas from './sagas/ClientsSagas'

export default function* rootSaga() {
    yield all([
        ...UserSagas,
        ...BrandCategoriesSagas,
        ...ServicesSagas,
        ...BrandsSagas,
        ...AvailabilitiesSagas,
        ...CalendarSagas,
        ...ClientsSagas,
        ...AppointmentsSagas,
    ])
}
