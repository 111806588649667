import {combineReducers} from 'redux'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import NavigationReducer from './reducers/NavigationReducer'
import AppointmentsReducer from "./reducers/AppointmentsReducer";
import LoginReducer from "./reducers/LoginReducer";
import ClientsReducer from "./reducers/ClientsReducer";
import BrandCategoriesReducer from "./reducers/BrandCategoriesReducer";
import ServicesReducer from "./reducers/ServicesReducer";
import BrandsReducer from "./reducers/BrandsReducer";
import UserReducer from "./reducers/UserReducer";
import AvailabilitiesReducer from "./reducers/AvailabilitiesReducer";
import CalendarReducer from "./reducers/CalendarReducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['brandCategories', 'services', 'brands', 'user']
  }

const RootReducer = combineReducers({
    navigations: NavigationReducer,
    login: LoginReducer,
    appointments: AppointmentsReducer,
    clients: ClientsReducer,
    brandCategories: BrandCategoriesReducer,
    brands: BrandsReducer,
    user: UserReducer,
    services: ServicesReducer,
    availabilities: AvailabilitiesReducer,
    calendar: CalendarReducer
})

export default persistReducer(persistConfig, RootReducer)
