import AuthGuard from 'app/guards/AuthGuard';
import HomePage from "app/views/home/HomePage";
import NotFoundPage from 'app/views/not-found/NotFoundPage';
import MatxLayout from './components/MatxLayout/MatxLayout';
import {DashboardRoutes} from "./views/dashboard/DashboardRoutes";
import {AuthRoutes} from "./views/auth/AuthRoutes";

export const RootRoutes = () => {
    return [
        {
            path: '/',
            element: <HomePage/>,
        },
        ...AuthRoutes,
        {
            element: (
                <AuthGuard>
                    <MatxLayout/>
                </AuthGuard>
            ),
            children: [...DashboardRoutes],
        },
        {
            path: '*',
            element: <NotFoundPage/>,
        },
    ]
}
