import { createSelector } from 'reselect'
import { transformAvailabilitiesList } from '../utils/CalendarUtils'
import { transformAppointmentsServices } from '../utils/AppointmentsUtils'
import { selectServicesList } from "./ServicesSelectors";

const selectCalendar = (state) => state.calendar

export const selectCurrentDate = createSelector(
  selectCalendar,
  (calendar) => calendar.currentDate
)

export const selectCurrentView = createSelector(
  selectCalendar,
  (calendar) => calendar.currentView
)

export const selectSearchParams = createSelector(
  selectCalendar,
  (calendar) => calendar.searchParams
)

const selectAvailabilities = createSelector(
  selectCalendar,
  calendar => calendar.availabilities
)

export const selectAvailabilitiesList = createSelector(
  selectAvailabilities,
  availabilities => transformAvailabilitiesList(availabilities.list)
)

const selectAppointments = createSelector(
  selectCalendar,
  calendar => calendar.appointments
)

export const selectAppointmentsList = createSelector(
  [selectAppointments, selectServicesList],
  (appointments, services) => transformAppointmentsServices(appointments.list, services)
)

export const selectIsFetching = createSelector(
  [selectAppointments, selectAvailabilities],
  (appointments, availabilities) => appointments.isFetching || availabilities.isFetching
)