const ClientsTypes = {
    SAVAN_FETCH_CLIENTS_START: 'SAVAN_FETCH_CLIENTS_START',
    SAVAN_FETCH_CLIENTS_SUCCESS: 'SAVAN_FETCH_CLIENTS_SUCCESS',
    SAVAN_FETCH_CLIENTS_FAILED: 'SAVAN_FETCH_CLIENTS_FAILED',
    SAVAN_CLIENTS_FILTER_CHANGE: 'SAVAN_CLIENTS_FILTER_CHANGE',
    SAVAN_SAVE_CLIENT_START: 'SAVAN_SAVE_CLIENT_START',
    SAVAN_SAVE_CLIENT_SUCCESS: 'SAVAN_SAVE_CLIENT_SUCCESS',
    SAVAN_SAVE_CLIENT_FAILED: 'SAVAN_SAVE_CLIENT_FAILED',
}

export default ClientsTypes
