import LoginTypes from "../types/LoginTypes";

export const memberLoginInit = (isAuthenticated, user) => ({
    type: LoginTypes.SAVAN_MEMBER_INIT,
    payload: {isAuthenticated: isAuthenticated, user: user}
})

export const memberLoginSuccess = (token, user) => ({
    type: LoginTypes.SAVAN_MEMBER_LOGIN_SUCCESS,
    payload: {isAuthenticated: true, accessToken: token, user: user}
})

export const memberLoginFailed = () => ({
    type: LoginTypes.SAVAN_MEMBER_LOGIN_FAILED,
    payload: {isAuthenticated: false, accessToken: null, user: null}
})

export const memberLogoutSuccess = () => ({
    type: LoginTypes.SAVAN_MEMBER_LOGOUT_SUCCESS,
    payload: {isAuthenticated: false}
})
