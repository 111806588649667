import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
    fetchDataStart,
    fetchAppointmentsSuccess,
    fetchAppointmentsFailed,
    fetchAvailabilitiesSuccess,
    fetchAvailabilitiesFailed,
} from '../actions/CalendarActions'
import CalendarTypes from '../types/CalendarTypes'
import AvailabilitiesTypes from '../types/AvailabilitiesTypes'
import { fetchAppointments } from 'app/services/AppointmentsService'
import { fetchAvailabilities } from 'app/services/AvailabilitiesService'
import { selectSearchParams } from '../selectors/CalendarSelectors'
import { selectDistributorId } from "../selectors/UserSelectors";

function* onFetchAppointments() {
    try {
        const { after, before } = yield select(selectSearchParams)
        const params = {
            // status: 2,
            startDateTime: {
                after,
                before,
            }
        }
    const idDistributor = yield select(selectDistributorId)
    const { data : {data} } = yield call(fetchAppointments, idDistributor, params)
        yield put(fetchAppointmentsSuccess(data))
    } catch (error) {
        yield put(fetchAppointmentsFailed(error))
    }
}

function* onFetchAvailabilities() {
    try {
        const { after, before } = yield select(selectSearchParams)
        const distributor = yield select(selectDistributorId)
        const dayDispo = {
            after,
            before,
        }
        const { data } = yield call(fetchAvailabilities, { dayDispo, distributor })
        yield put(fetchAvailabilitiesSuccess(data))
    } catch (error) {
        yield put(fetchAvailabilitiesFailed(error))
    }
}

function* onFetchDataStart() {
    yield put(fetchDataStart())
}

const CalendarSagas = [
    takeLatest(
        CalendarTypes.SAVAN_FETCH_CALENDAR_DATA_START,
        onFetchAppointments
    ),
    takeLatest(
        CalendarTypes.SAVAN_FETCH_CALENDAR_DATA_START,
        onFetchAvailabilities
    ),
    takeLatest(
        AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_SUCCESS,
        onFetchAvailabilities
    ),
    takeLatest(
        CalendarTypes.SAVAN_CALENDAR_CHANGE_DATE,
        onFetchDataStart
    ),
    takeLatest(
        CalendarTypes.SAVAN_CALENDAR_CHANGE_VIEW,
        onFetchDataStart
    ),
    takeLatest(
      AvailabilitiesTypes.SAVAN_SAVE_AVAILABILITY_SUCCESS,
      onFetchDataStart
    ),
    takeLatest(
      AvailabilitiesTypes.SAVAN_DELETE_AVAILABILITY_SUCCESS,
      onFetchDataStart
    ),
]

export default CalendarSagas
