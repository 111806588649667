import {styled, Box, useTheme} from '@mui/system'
import React, { Fragment } from 'react'
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom'
import useSettings from 'app/hooks/useSettings'
import { Paragraph, Span } from '../Typography'
import {Badge, Icon, ButtonBase, useMediaQuery, InputAdornment, TextField} from '@mui/material'
import MatxVerticalNavExpansionPanel from './MatxVerticalNavExpansionPanel'
import useAuth from "../../hooks/useAuth";
import SearchIcon from "@mui/icons-material/Search";
import { selectAppointmentsCount } from "../../redux/selectors/AppointmentsSelectors";

const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
    fontSize: '12px',
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    display: mode === 'compact' && 'none',
    color: theme.palette.text.secondary,
}))

const ExtAndIntCommon = {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px',
    height: 44,
    whiteSpace: 'pre',
    marginBottom: '8px',
    textDecoration: 'none',
    justifyContent: 'space-between',
    transition: 'all 150ms ease-in',
    '&:hover': {
        background: 'rgba(255, 255, 255, 0.08)',
    },
    '&.compactNavItem': {
        overflow: 'hidden',
        justifyContent: 'center !important',
    },
    '& .icon': {
        fontSize: '18px',
        paddingLeft: '16px',
        paddingRight: '16px',
        verticalAlign: 'middle',
    },
}
const ExternalLink = styled('a')(({ theme }) => ({
    ...ExtAndIntCommon,
    color: theme.palette.text.primary,
}))

const InternalLink = styled(Box)(({ theme }) => ({
    '& a': {
        ...ExtAndIntCommon,
        color: theme.palette.text.primary,
    },
    '& .navItemActive': {
        backgroundColor: 'rgba(255, 255, 255, 0.16)',
    },
}))

const StyledText = styled(Span)(({ mode }) => ({
    fontSize: '0.875rem',
    paddingLeft: '0.8rem',
    display: mode === 'compact' && 'none',
}))

const BulletIcon = styled('div')(({ theme }) => ({
    padding: '2px',
    marginLeft: '24px',
    marginRight: '8px',
    overflow: 'hidden',
    borderRadius: '300px',
    background: theme.palette.text.primary,
}))

const BadgeValue = styled('div')(() => ({
    padding: '1px 8px',
    overflow: 'hidden',
    borderRadius: '300px',
}))

const SearchTextField = styled(TextField)({
    borderRadius: 4,
    '& label.Mui-focused': {
        color: '#000',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#F5F5F5',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F5F5F5',
        },
        '&:hover fieldset': {
            borderColor: '#F5F5F5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F5F5F5',
        },
    },
});

const MenuBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: -12,
    backgroundColor: '#FA274D',
    color: '#fff'
  }
})

const menuItems = [
    {
        name: 'Profil',
        path: '/profile',
        icon: 'person',
    },
]

const MatxVerticalNav = ({ items }) => {
    const { settings } = useSettings()
    const { mode } = settings.layout1Settings.leftSidebar
    const { logout } = useAuth()
    const theme = useTheme()
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    const appointmentsCount = useSelector(selectAppointmentsCount)

    const renderLevels = (data) => {
        return data.map((item, index) => {
            if (item.type === 'label')
                return (
                    <ListLabel
                        key={index}
                        mode={mode}
                        className="sidenavHoverShow"
                    >
                        {item.label}
                    </ListLabel>
                )
            if (item.children) {
                return (
                    <MatxVerticalNavExpansionPanel
                        mode={mode}
                        item={item}
                        key={index}
                    >
                        {renderLevels(item.children)}
                    </MatxVerticalNavExpansionPanel>
                )
            } else if (item.type === 'extLink') {
                return (
                    <ExternalLink
                        key={index}
                        href={item.path}
                        className={`${mode === 'compact' && 'compactNavItem'}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <ButtonBase
                            key={item.name}
                            name="child"
                            sx={{ width: '100%' }}
                        >
                            {(() => {
                                if (item.icon) {
                                    return (
                                        <Icon className="icon">
                                            {item.icon}
                                        </Icon>
                                    )
                                } else {
                                    return (
                                        <span className="item-icon icon-text">
                                            {item.iconText}
                                        </span>
                                    )
                                }
                            })()}
                            <StyledText
                                mode={mode}
                                className="sidenavHoverShow"
                            >
                                {item.name}
                            </StyledText>
                            <Box mx="auto" />
                            {item.badge && (
                                <BadgeValue>{item.badge.value}</BadgeValue>
                            )}
                        </ButtonBase>
                    </ExternalLink>
                )
            } else {
                return (
                    <InternalLink key={index}>
                        <NavLink
                            to={item.path}
                            className={({ isActive }) =>
                                isActive ? `navItemActive ${mode === 'compact' && 'compactNavItem'}` : `${mode === 'compact' && 'compactNavItem'}`
                            }
                        >
                            <ButtonBase
                                key={item.name}
                                name="child"
                                sx={{ width: '100%' }}
                                disabled={item.disabled}
                            >
                                {item?.icon ? (
                                    <Icon className="icon" sx={item.disabled ? { width: 36, color: '#adadad' } : { width: 36 }}>
                                        {item.icon}
                                    </Icon>
                                ) : (
                                    <Fragment>
                                        <BulletIcon
                                            className={`nav-bullet`}
                                            sx={{
                                                display:
                                                    mode === 'compact' && 'none',
                                            }}
                                        />
                                        <Box
                                            className="nav-bullet-text"
                                            sx={{
                                                ml: '20px',
                                                fontSize: '11px',
                                                display:
                                                    mode !== 'compact' && 'none',
                                            }}
                                        >
                                            {item.iconText}
                                        </Box>
                                    </Fragment>
                                )}
                              <MenuBadge badgeContent={item.name === 'À confirmer' ? appointmentsCount : 0}>
                                <StyledText
                                  mode={mode}
                                  className="sidenavHoverShow"
                                  sx={ item.disabled ? {color: '#adadad'} : {}}
                                >
                                  {item.name}
                                </StyledText>
                              </MenuBadge>

                                <Box mx="auto" />
                                {/*{item.badge && (
                                    <BadgeValue className="sidenavHoverShow">
                                        {item.badge.value}
                                    </BadgeValue>
                                )}*/}
                            </ButtonBase>
                        </NavLink>
                    </InternalLink>
                )
            }
        })
    }

    return (
        <div className="navigation">
            {isMdScreen && (
              <Box display="flex" alignItems="center">
                  <SearchTextField
                    placeholder="Rechercher"
                    fullWidth
                    size="small"
                    sx={{
                        backgroundColor: '#F5F5F5',
                        width: '350px',
                    }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                              <SearchIcon />
                          </InputAdornment>
                        ),
                    }}
                  />
              </Box>
            )}
            {renderLevels(items)}
            {isMdScreen && (
              <>
                  {renderLevels(menuItems)}
                  <InternalLink onClick={logout}>
                      <NavLink
                        to='#'
                      >
                          <ButtonBase
                            name="child"
                            sx={{ width: '100%' }}
                          >
                              <Icon className="icon" sx={{ width: 36 }}>
                                  power_settings_new
                              </Icon>
                              <StyledText
                                mode={mode}
                                className="sidenavHoverShow"
                              >
                                  Déconnexion
                              </StyledText>
                              <Box mx="auto"/>
                          </ButtonBase>
                      </NavLink>
                  </InternalLink>
              </>
            )}
        </div>
    )
}

export default React.memo(MatxVerticalNav)
